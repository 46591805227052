import React from 'react'

interface ColumnProps {
  children?: React.ReactNode
  className?: string
}

const Column: React.FC<ColumnProps> = ({ children, className }) => {
  return <div className={`${className}`}>{children}</div>
}

interface GridProps {
  children: React.ReactNode
  className?: string
}

const Grid: React.FC<GridProps> = ({ children, className = '' }) => {
  return (
    <div
      className={`grid grid-cols-12 gap-3 w-full max-w-1920 m-auto px-4 md:px-16 ${className}`}
    >
      {children}
    </div>
  )
}

export { Column, Grid }
