import { PortableText } from '@portabletext/react'
import Link from 'next/link'
import { Fragment, useState } from 'react'

import { Navigation } from '~/lib/sanity.queries'

import Button from './Button'
import { EmailIcon } from './icons/EmailIcon'
import { TelegramIcon } from './icons/TelegramIcon'
import { TwitterIcon } from './icons/TwitterIcon'
import ServiceStatusBadge from './ServiceStatusBadge'
import StaggerText from './StaggerText'

export default function Footer({ navigation }: { navigation: Navigation }) {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)

  return (
    <footer className="flex flex-col max-w-1920 m-auto gap-y-1 md:gap-y-6 pt-20 px-4 md:px-16 mt-auto mb-0 w-full">
      <div className="flex flex-col xl:flex-row w-full justify-between items-start text-grey mb-20">
        <div className="flex flex-col items-start gap-y-5 md:gap-y-5 mb-20 xl:mb-0 flex-shrink-0 mr-10">
          <div className="text-gradient h5">
            <PortableText value={navigation.footer.heading} />
          </div>
          <Button href={navigation.footer.cta.url} asLink>
            {navigation.footer.cta.text}
          </Button>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 w-full xl:w-2/3 2xl:w-1/2 gap-y-10">
          <div className="flex flex-col gap-y-2 md:gap-y-2">
            <div className="mono-large mb-4 md:mb-2 text-14 md:text-sm">
              Sitemap
            </div>
            {navigation.main.map((item, index) =>
              item?.subitems?.length > 0 ? (
                <Fragment key={index}>
                  {item?.subitems?.map((subitem, subIndex) => (
                    <div key={subIndex}>
                      <Link
                        key={subIndex}
                        href={subitem.url}
                        className="footer-nav-item"
                        onMouseEnter={() => setHoveredItem(subitem.text)}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <StaggerText
                          isHovered={hoveredItem === subitem.text}
                          text={subitem.text}
                        />
                      </Link>
                    </div>
                  ))}
                </Fragment>
              ) : (
                <div key={index}>
                  <Link
                    href={item.page.url}
                    className="footer-nav-item"
                    onMouseEnter={() => setHoveredItem(item.page.text)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <StaggerText
                      isHovered={hoveredItem === item.page.text}
                      text={item.page.text}
                    />
                  </Link>
                </div>
              ),
            )}
          </div>
          {navigation.footer.secondaryMenu.map((footerItem, index) => (
            <div
              key={index}
              className="flex flex-col gap-y-2 md:gap-y-2 items-start"
            >
              <div className="mono-large mb-4 md:mb-2 text-14 md:text-sm">
                {footerItem.heading}
              </div>
              {footerItem.subitems.map((subitem, subIndex) => (
                <Link
                  key={subIndex}
                  href={subitem.url}
                  className="footer-nav-item"
                  onMouseEnter={() => setHoveredItem(subitem.text)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <StaggerText
                    isHovered={hoveredItem === subitem.text}
                    text={subitem.text}
                  />
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Social and Service Status */}
      <div className="flex flex-col xl:flex-row w-full xl:justify-between items-start xl:items-center mb-4 md:mb-0">
        <div className="flex flex-row items-center gap-x-4 text-green mb-6 xl:mb-0">
          <Link
            className="h-6 transition duration-300 hover:opacity-50"
            href="https://twitter.com/NodeAIETH"
            aria-label="Twitter"
          >
            <TwitterIcon className="h-full" />
          </Link>
          <Link
            className="h-6 transition duration-300 hover:opacity-50"
            href="https://t.me/nodeaieth"
            aria-label="Telegram"
          >
            <TelegramIcon className="h-full" />
          </Link>
          <Link
            className="h-6 transition duration-300 hover:opacity-50"
            href="mailto:hello@nodes.ai"
            aria-label="Email"
          >
            <EmailIcon className="h-full" />
          </Link>
        </div>
        <ServiceStatusBadge />
      </div>

      <div className="flex flex-row w-full justify-between items-center mono-small text-grey border-t border-grey border-opacity-20 py-6">
        <div>©2024</div>
        <div>All Rights Reserved</div>
        <div>node.ai</div>
      </div>
    </footer>
  )
}
