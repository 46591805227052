import { SVGProps } from 'react'

export const EmailIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.55478 4.77246C1.90892 4.77246 0.560547 6.12083 0.560547 7.76669V24.235C0.560547 25.8808 1.90892 27.2292 3.55478 27.2292H29.0058C30.6516 27.2292 32 25.8808 32 24.235V7.76669C32 6.12083 30.6516 4.77246 29.0058 4.77246H3.55478ZM3.55478 6.26958H29.0058C29.2164 6.26958 29.4107 6.31199 29.5906 6.38654L17.3563 17.0184C16.675 17.61 15.861 17.61 15.1808 17.0184L2.96997 6.38654C3.14981 6.31199 3.34419 6.26958 3.55478 6.26958ZM2.08106 7.60295L14.1983 18.1412C15.39 19.1779 17.1469 19.1763 18.3388 18.1412L30.4795 7.60295C30.4853 7.65797 30.5029 7.70982 30.5029 7.76669V24.235C30.5029 25.0773 29.8481 25.7321 29.0058 25.7321H3.55478C2.71243 25.7321 2.05766 25.0773 2.05766 24.235V7.76669C2.05766 7.70982 2.07522 7.65797 2.08106 7.60295Z"
        fill="currentColor"
      />
    </svg>
  )
}
