const ServiceStatusBadge = () => {
  return (
    <div
      className={`
      group  
      mono-small
      flex flex-row items-center justify-start
      border rounded-full
      h-10
      relative
      box-content
      transition-border transition-color transition-background-color duration-200
      hover:bg-white-10
      is-active text-white border-grey-50 bg-white-10
    `}
    >
      <div
        className="
        h-full
        pr-4
        transform w-10
        transition-width duration-200 delay-100
      "
      ></div>
      <div
        className="
        w-8 h-8
        absolute left-1
        transform scale-100
        transition-transform duration-200 delay-0
        bg-green
        rounded-full
      "
      ></div>
      <div
        className="
        h-full
        pl-1 pr-4
        flex flex-row items-center justify-start
      "
      >
        All Services Online
      </div>
    </div>
  )
}

export default ServiceStatusBadge
