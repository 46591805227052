import { memo } from 'react'

interface StaggerTextProps {
  text: string
  isHovered: boolean
  className?: string
}

const StaggerText = memo(
  ({ text, isHovered, className }: StaggerTextProps) => {
    const letters = text.split('').map((char, index) => ({
      char: char === ' ' ? '\u00A0' : char,
      delay: index * 10, // stagger delay in milliseconds
    }))

    return (
      <div className={`overflow-hidden relative ${className}`}>
        <span className="sr-only">{text}</span>
        <div className="flex">
          {letters.map((letter, index) => (
            <span
              key={index}
              className={`inline-block transition-transform duration-200 ${isHovered ? 'transform -translate-y-[110%]' : 'transform translate-y-0'}`}
              style={{ transitionDelay: `${letter.delay}ms` }}
            >
              {letter.char}
            </span>
          ))}
        </div>
        <div className="flex absolute top-full">
          {letters.map((letter, index) => (
            <span
              key={index + letters.length} // unique key for the second set
              className={`inline-block transition-transform duration-200 ${isHovered ? 'transform -translate-y-[110%]' : 'transform translate-y-0'}`}
              style={{ transitionDelay: `${letter.delay}ms` }}
            >
              {letter.char}
            </span>
          ))}
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.text === nextProps.text &&
      prevProps.isHovered === nextProps.isHovered
    )
  },
)

StaggerText.displayName = 'StaggerText'
export default StaggerText
