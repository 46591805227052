const ArrowRight = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1L15.2714 7.02449C16.2611 8.15557 16.2611 9.84444 15.2714 10.9755L10 17"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M17 9H0" stroke="currentColor" strokeWidth="2" />
    </svg>
  )
}

export default ArrowRight
