import { SmoothScrollbar } from '@14islands/r3f-scroll-rig'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { ReactNode } from 'react'

import { Navigation } from '~/lib/sanity.queries'

import Footer from './Footer'
import { Header } from './Header'

type LayoutProps = {
  children: ReactNode
  navigation: Navigation
  eventSourceRef?: React.MutableRefObject<HTMLDivElement>
}

gsap.registerPlugin(ScrollTrigger)

const Layout = ({ children, navigation, eventSourceRef }: LayoutProps) => {
  return (
    <>
      <div
        className={`z-50 min-h-screen flex flex-col w-full h-full relative`}
        ref={eventSourceRef}
      >
        <Header navigation={navigation} />
        <main className="relative z-10">
          {children}
          <Footer navigation={navigation} />
        </main>
        <div className="overflow-hidden absolute h-screen max-h-2xl w-full left-0 bottom-0 -z-10 pointer-events-none">
          <div className="-z-10 bg-gradient rounded-full w-full h-full transform translate-x-1/2 translate-y-1/2 blur-3xl opacity-20"></div>
        </div>
        <SmoothScrollbar />
      </div>
    </>
  )
}

export default Layout
