import Link from 'next/link'
import { useState } from 'react'

import ArrowRight from './icons/ArrowRight'
import StaggerText from './StaggerText'

type ButtonProps = {
  children: string
  onClick?: () => void
  href?: string
  asLink?: boolean
  pin?: 'left' | 'right' // Add pin prop
  variant?: 'green' | 'black'
  className?: string
  hasArrow?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const ButtonContent = ({
  isHovered,
  children,
  pin = 'left', // Default pin to 'left'
  variant = 'green',
  hasArrow = true,
}: {
  isHovered: boolean
  children: string
  pin: 'left' | 'right'
  variant?: 'green' | 'black'
  hasArrow?: boolean
}) => {
  const circlePositionClass =
    pin === 'right' ? 'right-0' : 'right-0 md:group-hover:translate-x-12'
  const buttonPositionClass =
    pin === 'right' ? 'md:group-hover:-translate-x-12' : ''

  return (
    <div
      className={`relative ${variant === 'black' ? 'text-green' : 'text-black'} flex`}
    >
      <div
        className={`
          relative flex flex-row items-center justify-center 
          px-4 md:px-8 h-10 md:h-12 gap-4 
          ${variant === 'black' ? 'bg-black' : 'bg-green'} rounded-full 
          mono-large text-14 md:text-sm
          transition-transform duration-500
          ${buttonPositionClass}
      `}
      >
        <StaggerText isHovered={isHovered} text={children} />
      </div>
      {hasArrow && (
        <div
          className={`
            md:absolute ${circlePositionClass} block top-0
            transform
            -z-20
            transition-transform duration-500
          `}
        >
          <div
            className={`
              flex items-center justify-center 
              w-10 h-10
              md:w-12 md:h-12
              p-3 md:p-0
              ${variant === 'black' ? 'bg-black' : 'bg-green'} rounded-full
            `}
          >
            <ArrowRight />
          </div>
        </div>
      )}
    </div>
  )
}

const Button = ({
  children,
  onClick,
  href,
  asLink,
  pin = 'left',
  variant = 'green',
  className = '',
  hasArrow = true,
  onMouseEnter,
  onMouseLeave,
}: ButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)
  if (asLink) {
    return (
      <Link
        href={href}
        className={`group ${className}`}
        onClick={onClick}
        onMouseEnter={() => {
          onMouseEnter && onMouseEnter()
          setIsHovered(true)
        }}
        onMouseLeave={() => {
          onMouseLeave && onMouseLeave()
          setIsHovered(false)
        }}
      >
        <ButtonContent
          hasArrow={hasArrow}
          variant={variant}
          isHovered={isHovered}
          pin={pin}
        >
          {children}
        </ButtonContent>
      </Link>
    )
  }

  return (
    <button
      className={`group z-10 relative ${className}`}
      onClick={onClick}
      onMouseEnter={() => {
        onMouseEnter && onMouseEnter()
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        onMouseLeave && onMouseLeave()
        setIsHovered(false)
      }}
    >
      <ButtonContent
        hasArrow={hasArrow}
        variant={variant}
        isHovered={isHovered}
        pin={pin}
      >
        {children}
      </ButtonContent>
    </button>
  )
}

export default Button
