import { SVGProps } from 'react'

export const TelegramOutlineIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.80981 10.2036C6.97307 8.82572 9.08192 7.91726 10.1363 7.47837C13.1504 6.2251 13.7759 6.00744 14.1845 6.00006C14.2743 5.99862 14.4743 6.02083 14.6049 6.12636C14.7134 6.21536 14.7439 6.33571 14.7591 6.42022C14.7727 6.50464 14.7913 6.69705 14.7761 6.84724C14.6133 8.56279 13.9064 12.7259 13.5471 14.6474C13.3962 15.4605 13.0961 15.7331 12.8062 15.7597C12.1756 15.8176 11.6976 15.3433 11.0873 14.9434C10.1329 14.3174 9.59382 13.9278 8.66662 13.317C7.59521 12.6112 8.29022 12.2231 8.90052 11.5891C9.05982 11.4232 11.8366 8.8981 11.8891 8.66908C11.8959 8.64043 11.9027 8.53363 11.8383 8.47735C11.7756 8.4209 11.6823 8.44023 11.6145 8.45548C11.5179 8.47718 9.99392 9.48545 7.03749 11.4803C6.60521 11.7776 6.21362 11.9226 5.86101 11.9149C5.47451 11.9066 4.72862 11.6959 4.17428 11.5159C3.4962 11.295 2.95543 11.1782 3.0029 10.8031C3.02663 10.6078 3.29617 10.4079 3.80981 10.2036Z"
        fill="currentColor"
      />
      <circle cx="10.35" cy="10.35" r="9.85" stroke="currentColor" />
    </svg>
  )
}
