import imageUrlBuilder from '@sanity/image-url'
import React from 'react'

import { getClient } from '~/lib/sanity.client'

export function formatDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

const builder = imageUrlBuilder(getClient())

export function urlFor(source) {
  return builder.image(source)
}

/**
 /**
  * Calculates the staggered delay for animations based on the index and total length.
  * @param {Object} params - The parameters for calculating delay.
  * @param {number} params.index - The current index of the item.
  * @param {number} params.length - The total number of items.
  * @param {boolean} [params.reverse=false] - Whether the stagger should be reversed.
  * @param {number} params.staggerIn - The stagger delay for incoming items.
  * @param {number} params.staggerOut - The stagger delay for outgoing items.
  * @param {number} params.baseDelayIn - The base delay for incoming items.
  * @param {number} params.baseDelayOut - The base delay for outgoing items.
  * @returns {number} The calculated delay in milliseconds.
  */
export const getStaggeredDelay = ({
  index,
  length,
  reverse = false,
  staggerIn = 75,
  staggerOut = 75,
  baseDelayIn = 0,
  baseDelayOut = 0,
}: {
  index: number
  length: number
  reverse?: boolean
  staggerIn?: number
  staggerOut?: number
  baseDelayIn?: number
  baseDelayOut?: number
}): number => {
  return reverse
    ? baseDelayIn + index * staggerIn
    : baseDelayOut + (length - index + 1) * staggerOut
}

/**
 * Truncates a string to a specified length and appends an ellipsis.
 * @param {string} str - The string to truncate.
 * @param {number} num - The number of characters to truncate the string to.
 * @returns {string} The truncated string.
 */
export function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}
