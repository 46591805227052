import { SVGProps } from 'react'

export const TelegramIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_584_3234)">
        <path
          d="M16 0C11.7575 0 7.685 1.68675 4.6875 4.68625C1.68691 7.68694 0.000831175 11.7565 0 16C0 20.2417 1.6875 24.3142 4.6875 27.3137C7.685 30.3132 11.7575 32 16 32C20.2425 32 24.315 30.3132 27.3125 27.3137C30.3125 24.3142 32 20.2417 32 16C32 11.7583 30.3125 7.68575 27.3125 4.68625C24.315 1.68675 20.2425 0 16 0Z"
          fill="#4CC054"
        />
        <path
          d="M7.24311 15.8311C11.9081 13.7991 15.0181 12.4593 16.5731 11.8121C21.0181 9.96381 21.9406 9.64281 22.5431 9.63193C22.6756 9.62981 22.9706 9.66256 23.1631 9.81818C23.3231 9.94943 23.3681 10.1269 23.3906 10.2516C23.4106 10.3761 23.4381 10.6598 23.4156 10.8813C23.1756 13.4113 22.1331 19.5508 21.6031 22.3846C21.3806 23.5836 20.9381 23.9856 20.5106 24.0248C19.5806 24.1103 18.8756 23.4108 17.9756 22.8211C16.5681 21.8978 15.7731 21.3233 14.4056 20.4226C12.8256 19.3816 13.8506 18.8093 14.7506 17.8743C14.9856 17.6296 19.0806 13.9058 19.1581 13.5681C19.1681 13.5258 19.1781 13.3683 19.0831 13.2853C18.9906 13.2021 18.8531 13.2306 18.7531 13.2531C18.6106 13.2851 16.3631 14.7721 12.0031 17.7138C11.3656 18.1523 10.7881 18.3661 10.2681 18.3548C9.69811 18.3426 8.59811 18.0318 7.78061 17.7663C6.78061 17.4406 5.98311 17.2683 6.05311 16.7151C6.08811 16.4271 6.48561 16.1323 7.24311 15.8311Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="clip0_584_3234">
          <rect width="32" height="32" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  )
}
